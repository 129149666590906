:root {
  --primary-color: #000000;
  --secondary-color: #333333;
  --accent-color: #666666;
  --text-color: #000000;
  --background-color: #ffffff;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Noto Sans TC', 'Roboto', sans-serif;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Noto Serif TC', 'Playfair Display', serif;
  font-weight: 700;
  line-height: 1.2;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section {
  padding: 4rem 0;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: var(--text-color);
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: var(--accent-color);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: darken(var(--accent-color), 10%);
  transform: translateY(-2px);
}

.card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}